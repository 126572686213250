<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="receivingSetHistory"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
                <c-btn
                  v-show="editable && isOld"
                  label="삭제"
                  icon="delete_forever"
                  @btnClicked="deleteInfo" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="receivingSetHistory.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  type="datetime"
                  default="today"
                  label="보고일시"
                  name="plantCd"
                  v-model="receivingSetHistory.reportDate" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="설비 정보" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-fire-fighting
                        required
                        sopFireFightingTypeCd="SFFT000070"
                        label="관련 소방설비"
                        name="sopFireFightingId"
                        v-model="receivingSetHistory.sopFireFightingId"
                        @dataChange="fireFightingChange">
                      </c-fire-fighting>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <c-text
                        disabled
                        :editable="editable"
                        label="소방설비 종류"
                        name="sopFireFightingTypeName"
                        v-model="receivingSetHistory.sopFireFightingTypeName">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="중계기 종류"
                        name="repeaterType"
                        v-model="receivingSetHistory.repeaterType">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="중계기 번호"
                        name="repeater"
                        v-model="receivingSetHistory.repeater">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                      <c-text
                        :editable="editable"
                        label="제조사"
                        name="repeaterMenufCompany"
                        v-model="receivingSetHistory.repeaterMenufCompany">
                      </c-text>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="장소" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                      <c-text
                        :editable="editable"
                        label="동"
                        name="locationDong"
                        v-model="receivingSetHistory.locationDong">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
                      <c-text
                        :editable="editable"
                        label="층"
                        name="locationFloor"
                        v-model="receivingSetHistory.locationFloor">
                      </c-text>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8">
                      <c-text
                        :editable="editable"
                        label="세부위치"
                        name="locationDetail"
                        v-model="receivingSetHistory.locationDetail">
                      </c-text>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="발생내용" 
                  name="happened" 
                  v-model="receivingSetHistory.happened" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="미흡사항" 
                  name="insufficiencies" 
                  v-model="receivingSetHistory.insufficiencies" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="원인분석" 
                  name="causeAnalysis" 
                  v-model="receivingSetHistory.causeAnalysis" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-card title="근무자 대피" class="cardClassDetailForm" topClass="topcolor-blue">
                  <template slot="card-detail">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <c-checkbox
                        :editable="editable"
                        :isFlag="true"
                        label="대피유무"
                        name="evacuationFlag"
                        v-model="receivingSetHistory.evacuationFlag"
                      />
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <c-text
                        :editable="editable"
                        type="number"
                        label="대피인원"
                        name="evacuationCount"
                        v-model="receivingSetHistory.evacuationCount">
                      </c-text>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <c-textarea
                        :editable="editable"
                        :rows="1"
                        label="대피내용 / 미대피 사유"
                        name="evacuationDetails"
                        v-model="receivingSetHistory.evacuationDetails">
                      </c-textarea>
                    </div>
                  </template>
                </c-card>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="개선방안" 
                  name="improvement" 
                  v-model="receivingSetHistory.improvement" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-textarea
                  :editable="editable"
                  :rows="2"
                  label="비고" 
                  name="remarks" 
                  v-model="receivingSetHistory.remarks" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="감지기 동작 위치" class="cardClassDetailForm mapCard">
            <!-- <template slot="card-button">
              <c-pick-file :editable="editable" outlined v-model="file" :dense="true" @getImageData="getImageData">
                <template v-slot:prepend>
                  <q-icon name="attach_file"></q-icon>
                </template>
              </c-pick-file>
            </template> -->
            <template slot="card-detail">
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <q-img :src="receivingSetHistory.detectorOperatingPositionSrc"></q-img>
              </div> -->
              <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <template v-if="mapExists">
                  <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
                    <q-img
                      id="zoomerImage"
                      :src="map.mapSrc"
                      :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
                    >
                        <c-map-marker 
                          v-for="(mark, idx) in map.marks"
                          :key="idx"
                          class="mark-firefighting"
                          :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 25}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                          :isSelected="true">
                          <template v-slot:innerMark>
                            <q-menu 
                              :ref="'markedproxy' + mark.idx" 
                              :content-class="'firefighting-menu-layer'"
                              anchor="top right"
                              self="top left">
                              <q-card 
                                flat bordered 
                                @click="openFireFightingPop(mark)"
                                :class="['firefighting-menu-card']">
                                <q-item class="firefighting-card-section firefighting-card-item">
                                  <q-item-section>
                                    <q-item-label>
                                      <q-badge rounded color="light-blue" :label="mark.sopFireFightingTypeName" />
                                    </q-item-label>
                                  </q-item-section>
                                  <q-item-section side>
                                    <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="deleteFireFightingMark(mark)" />
                                  </q-item-section>
                                </q-item>
                                <q-card-section class="firefighting-card-section cursor-pointer">
                                  <q-icon 
                                    name="chevron_right" 
                                    class="text-indigo" 
                                  />
                                  <b>소방설비명</b> : {{mark.fireFightingName}}
                                </q-card-section>
                                <q-card-section class="firefighting-card-section cursor-pointer">
                                  <q-icon 
                                    name="chevron_right" 
                                    class="text-indigo" 
                                  />
                                  <b>설치번호</b> : {{mark.manageNo}}
                                </q-card-section>
                              </q-card>
                            </q-menu>
                          </template>
                        </c-map-marker>
                    </q-img>
                  </panZoom>
                </template>
                <el-empty v-else 
                  :image-size="435"
                  description="등록된 지도가 없습니다."></el-empty>
              </div>
            </template>
          </c-card>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-upload 
                :isPhoto="true"
                :attachInfo="attachInfo"
                :editable="editable"
                label="관련 사진">
              </c-upload>
            </div>
          </div>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingReceivingSetHistoryInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingReceivingSetHistoryId: '',
      }),
    },
    receivingSetHistory: {
      type: Object,
      default: () => ({
        sopFireFightingReceivingSetHistoryId: '',  // 화재수신기 작동 이력 보고 일련번호
        plantCd: null,  // 사업장코드
        reportDate: '',  // 보고일
        locationDong: '',  // 장소_동
        locationFloor: '',  // 장소_층
        locationDetail: '',  // 세부위치
        sopFireFightingId: '',  // 소방설비 일련번호
        fireFightingName: '',  // 소방설비명
        sopFireFightingTypeName: '',  // 소방설비 종류명
        repeaterType: '',  // 중계기 번호
        repeater: '',  // 중계기
        repeaterMenufCompany: '',  // 제조사
        happened: '',  // 발생내용
        insufficiencies: '',  // 미흡사항
        causeAnalysis: '',  // 원인분석
        evacuationFlag: 'N',  // 대피유무
        evacuationCount: '',  // 대피인원
        evacuationDetails: '',  // 대피내용, 미대피 사유
        improvement: '',  // 개선방안
        remarks: '',  // 비고
        detectorOperatingPositionSrc: '', // 감지기 동작 위치
        maps: [],
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'FIRE_RECEIVING_SET',
        taskKey: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 0.9,
          initialX: 450,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function() {
            return false;
          }
        },
        maps: [],
      },
      file: null,
      editable: true,
      isSave: false,
      mapListUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    map() {
      let map = {
        sopFireFightingMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.receivingSetHistory.maps[0]
      }
      return map;
    },
    mapExists() {
      return this.receivingSetHistory.maps 
        && this.receivingSetHistory.maps.length > 0
        && Boolean(this.receivingSetHistory.maps[0].sopFireFightingMapId)
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.mapListUrl = selectConfig.fft.fireFightingResultMap.list.url
      this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingReceivingSetHistory.delete.url
      // code setting
      // list setting
    },
    getFireFightingMap(sopFireFightingId) {
      this.$http.url = this.mapListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sopFireFightingId: sopFireFightingId ? sopFireFightingId : this.receivingSetHistory.sopFireFightingId
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.receivingSetHistory.maps = _result.data
        } else {
          this.receivingSetHistory.maps = [];
        }
      },);
    },
    fireFightingChange(sopFireFightingId, beforeSopFireFightingId, fireFighting) {
      if (fireFighting) {
        this.$set(this.receivingSetHistory, 'fireFightingName', fireFighting.fireFightingName)
        this.$set(this.receivingSetHistory, 'sopFireFightingTypeName', fireFighting.sopFireFightingTypeName)
        this.getFireFightingMap(sopFireFightingId);
      } else {
        this.$set(this.receivingSetHistory, 'fireFightingName', null)
        this.$set(this.receivingSetHistory, 'sopFireFightingTypeName', null)
      }
    },
    openFireFightingPop(mark) {
      this.openMarkInfo = mark
      this.popupOptions.title = '소방설비 상세';
      this.popupOptions.param = {
        sopFireFightingId: mark.sopFireFightingId,
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingMasterDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    // getImageData(data) {
    //   this.$set(this.receivingSetHistory, 'detectorOperatingPositionSrc', data)
    // },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingReceivingSetHistory.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.receivingSetHistory.regUserId = this.$store.getters.user.userId;
              this.receivingSetHistory.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.sopFireFightingReceivingSetHistoryId) {
        this.$set(this.attachInfo, 'taskKey', result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$set(this.popupParam, 'sopFireFightingReceivingSetHistoryId', result.data)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingReceivingSetHistoryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
<style lang="sass">
.mark-firefighting
  background: unset !important
  cursor: pointer

.firefighting-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.firefighting-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .firefighting-card-section
    padding: 0 10px 0 10px
  .firefighting-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: hidden

.mapCard
  .customCardbody
    padding: 0 !important
    margin: 0 !important
</style>