var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "LBLBASEINFO" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable,
                                expression: "editable",
                              },
                            ],
                            attrs: {
                              url: _vm.saveUrl,
                              isSubmit: _vm.isSave,
                              param: _vm.receivingSetHistory,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveInfo,
                              btnCallback: _vm.saveCallback,
                            },
                          }),
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editable && _vm.isOld,
                                expression: "editable && isOld",
                              },
                            ],
                            attrs: { label: "삭제", icon: "delete_forever" },
                            on: { btnClicked: _vm.deleteInfo },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            disabled: _vm.isOld,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.receivingSetHistory.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "plantCd", $$v)
                            },
                            expression: "receivingSetHistory.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "datetime",
                            default: "today",
                            label: "보고일시",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.receivingSetHistory.reportDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "reportDate",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.reportDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: {
                              title: "설비 정보",
                              topClass: "topcolor-blue",
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-fire-fighting", {
                                    attrs: {
                                      required: "",
                                      sopFireFightingTypeCd: "SFFT000070",
                                      label: "관련 소방설비",
                                      name: "sopFireFightingId",
                                    },
                                    on: { dataChange: _vm.fireFightingChange },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .sopFireFightingId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "sopFireFightingId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.sopFireFightingId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: "",
                                      editable: _vm.editable,
                                      label: "소방설비 종류",
                                      name: "sopFireFightingTypeName",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .sopFireFightingTypeName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "sopFireFightingTypeName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.sopFireFightingTypeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "중계기 종류",
                                      name: "repeaterType",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.repeaterType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeaterType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeaterType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "중계기 번호",
                                      name: "repeater",
                                    },
                                    model: {
                                      value: _vm.receivingSetHistory.repeater,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeater",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeater",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-4 col-lg-4",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "제조사",
                                      name: "repeaterMenufCompany",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .repeaterMenufCompany,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "repeaterMenufCompany",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.repeaterMenufCompany",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: { title: "장소", topClass: "topcolor-blue" },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-2 col-lg-2",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "동",
                                      name: "locationDong",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationDong,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationDong",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationDong",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-2 col-lg-2",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "층",
                                      name: "locationFloor",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationFloor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationFloor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationFloor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-8 col-lg-8",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      label: "세부위치",
                                      name: "locationDetail",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.locationDetail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "locationDetail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.locationDetail",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "발생내용",
                            name: "happened",
                          },
                          model: {
                            value: _vm.receivingSetHistory.happened,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "happened", $$v)
                            },
                            expression: "receivingSetHistory.happened",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "미흡사항",
                            name: "insufficiencies",
                          },
                          model: {
                            value: _vm.receivingSetHistory.insufficiencies,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "insufficiencies",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.insufficiencies",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "원인분석",
                            name: "causeAnalysis",
                          },
                          model: {
                            value: _vm.receivingSetHistory.causeAnalysis,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "causeAnalysis",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.causeAnalysis",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm",
                            attrs: {
                              title: "근무자 대피",
                              topClass: "topcolor-blue",
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-checkbox", {
                                    attrs: {
                                      editable: _vm.editable,
                                      isFlag: true,
                                      label: "대피유무",
                                      name: "evacuationFlag",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.evacuationFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationFlag",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationFlag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-6 col-sm-6 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.editable,
                                      type: "number",
                                      label: "대피인원",
                                      name: "evacuationCount",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory.evacuationCount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationCount",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationCount",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                                },
                                [
                                  _c("c-textarea", {
                                    attrs: {
                                      editable: _vm.editable,
                                      rows: 1,
                                      label: "대피내용 / 미대피 사유",
                                      name: "evacuationDetails",
                                    },
                                    model: {
                                      value:
                                        _vm.receivingSetHistory
                                          .evacuationDetails,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.receivingSetHistory,
                                          "evacuationDetails",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "receivingSetHistory.evacuationDetails",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "개선방안",
                            name: "improvement",
                          },
                          model: {
                            value: _vm.receivingSetHistory.improvement,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.receivingSetHistory,
                                "improvement",
                                $$v
                              )
                            },
                            expression: "receivingSetHistory.improvement",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.receivingSetHistory.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.receivingSetHistory, "remarks", $$v)
                            },
                            expression: "receivingSetHistory.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm mapCard",
                  attrs: { title: "감지기 동작 위치" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "zommer-container col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        style: `height: ${_vm.zoomer.height}px;`,
                      },
                      [
                        _vm.mapExists
                          ? [
                              _vm.mapExists
                                ? _c(
                                    "panZoom",
                                    {
                                      ref: "panZoom",
                                      attrs: {
                                        selector: "#zoomerImage",
                                        options: _vm.zoomer.options,
                                      },
                                    },
                                    [
                                      _c(
                                        "q-img",
                                        {
                                          style: `width: ${
                                            (_vm.zoomer.height - 30) *
                                            _vm.map.ratio
                                          }px; height: ${
                                            _vm.zoomer.height - 30
                                          }px;`,
                                          attrs: {
                                            id: "zoomerImage",
                                            src: _vm.map.mapSrc,
                                          },
                                        },
                                        _vm._l(
                                          _vm.map.marks,
                                          function (mark, idx) {
                                            return _c("c-map-marker", {
                                              key: idx,
                                              staticClass: "mark-firefighting",
                                              style: `transform: translate(${
                                                mark.locationXcoordinate *
                                                  _vm.zoomer.ratio -
                                                15
                                              }px, ${
                                                mark.locationYcoordinate *
                                                  _vm.zoomer.ratio -
                                                25
                                              }px);z-index: auto; user-select: auto; width: 30px; height: 30px;`,
                                              attrs: { isSelected: true },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "innerMark",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "q-menu",
                                                          {
                                                            ref:
                                                              "markedproxy" +
                                                              mark.idx,
                                                            refInFor: true,
                                                            attrs: {
                                                              "content-class":
                                                                "firefighting-menu-layer",
                                                              anchor:
                                                                "top right",
                                                              self: "top left",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "q-card",
                                                              {
                                                                class: [
                                                                  "firefighting-menu-card",
                                                                ],
                                                                attrs: {
                                                                  flat: "",
                                                                  bordered: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openFireFightingPop(
                                                                        mark
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "q-item",
                                                                  {
                                                                    staticClass:
                                                                      "firefighting-card-section firefighting-card-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-item-section",
                                                                      [
                                                                        _c(
                                                                          "q-item-label",
                                                                          [
                                                                            _c(
                                                                              "q-badge",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    rounded:
                                                                                      "",
                                                                                    color:
                                                                                      "light-blue",
                                                                                    label:
                                                                                      mark.sopFireFightingTypeName,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "q-item-section",
                                                                      {
                                                                        attrs: {
                                                                          side: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "q-icon",
                                                                          {
                                                                            staticClass:
                                                                              "cursor-pointer",
                                                                            attrs:
                                                                              {
                                                                                name: "delete",
                                                                                color:
                                                                                  "red",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.deleteFireFightingMark(
                                                                                    mark
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "q-card-section",
                                                                  {
                                                                    staticClass:
                                                                      "firefighting-card-section cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-indigo",
                                                                        attrs: {
                                                                          name: "chevron_right",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "소방설비명"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " : " +
                                                                        _vm._s(
                                                                          mark.fireFightingName
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "q-card-section",
                                                                  {
                                                                    staticClass:
                                                                      "firefighting-card-section cursor-pointer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-indigo",
                                                                        attrs: {
                                                                          name: "chevron_right",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "설치번호"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " : " +
                                                                        _vm._s(
                                                                          mark.manageNo
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _c("el-empty", {
                              attrs: {
                                "image-size": 435,
                                description: "등록된 지도가 없습니다.",
                              },
                            }),
                      ],
                      2
                    ),
                  ]),
                ],
                2
              ),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" },
                  [
                    _c("c-upload", {
                      attrs: {
                        isPhoto: true,
                        attachInfo: _vm.attachInfo,
                        editable: _vm.editable,
                        label: "관련 사진",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }